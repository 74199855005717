import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import { colors } from "../Styles/colors.js";

import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/Inter";
export default function NewsItems({
  item,
  updateSearch,
  selectedStoryTitle,
  selectedStoryAuthor,
  setSelectedStoryTitle,
  setSelectedStoryAuthor,
  setSelectedStoryAuthorIcon,
  setSelectedStoryArticleCategory,
  setSelectedStoryImage,
  setSelectedStoryArticleStart,
  setSelectedStoryArticleContent,
  setSelectedStoryArticleLink,
  bookmarked,
  pressBookmark,
}) {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  //Passing in the article props
  const ArticleAuthorIcon = item.ArticleAuthorIcon;
  const ArticleAuthor = item.ArticleAuthor;
  const ArticleTitle = item.ArticleTitle;
  const ArticleCategory = item.ArticleCategory;
  const ArticleStart = item.ArticleStart;
  const ArticleContent = item.ArticleContent;
  const ArticleImage = item.ArticleImage;
  const ArticleLink = item.ArticleLink;

  //Consolidate all of the article items to pass into redux
  const bookmarkArticle = {
    ArticleAuthorIcon: ArticleAuthorIcon,
    ArticleAuthor: ArticleAuthor,
    ArticleTitle: ArticleTitle,
    ArticleCategory: ArticleCategory,
    ArticleStart: ArticleStart,
    ArticleContent: ArticleContent,
    ArticleImage: ArticleImage,
    ArticleLink: ArticleLink,
  };

  const clickedStory = () => {
    setSelectedStoryTitle(ArticleTitle);
    setSelectedStoryAuthor(ArticleAuthor);
    setSelectedStoryAuthorIcon(ArticleAuthorIcon);
    setSelectedStoryArticleCategory(ArticleCategory);
    setSelectedStoryImage(ArticleImage);
    setSelectedStoryArticleStart(ArticleStart);
    setSelectedStoryArticleContent(ArticleContent);
    setSelectedStoryArticleLink(ArticleLink);
  };
  const [bookmarks, setBookmarks] = useState([]);

  //set the bookmarks
  useEffect(() => {
    setBookmarks(bookmarked);
    //console.log(bookmarked);
  }, [bookmarked]);

  return (
    <View style={styles.container}>
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
            style={[
              {
                shadowColor:
                  selectedStoryTitle == ArticleTitle &&
                  selectedStoryAuthor == ArticleAuthor
                    ? "rgba(0, 0, 0, 0.0)"
                    : "rgba(0, 0, 0, 0.2)",
                shadowRadius: 5,
                shadowOpacity: 0.5,
                shadowOffset: {
                  height: 1,
                  width: 1,
                },
                backgroundColor: hovered
                  ? colors.greySidebar
                  : selectedStoryTitle == ArticleTitle &&
                    selectedStoryAuthor == ArticleAuthor
                  ? colors.greySidebar
                  : "white",
              },
              styles.innerContainer,
            ]}
            onPress={() => clickedStory()}
          >
            <View style={styles.articleContentContainer}>
              <View style={styles.articleAuthorContainer}>
                <TouchableOpacity onPress={() => updateSearch(ArticleAuthor)}>
                  <View style={styles.articleAuthorImageContainer}>
                    <Image
                      source={ArticleAuthorIcon}
                      style={styles.articleAuthorImage}
                    />
                  </View>
                </TouchableOpacity>
                <Hoverable>
                  {({ hoveredAuthor }) => (
                    <TouchableOpacity
                      onPress={() => updateSearch(ArticleAuthor)}
                    >
                      <Text
                        style={[
                          styles.articleAuthorText,
                          {
                            fontFamily: hoveredAuthor
                              ? "Inter_800SemiBlack"
                              : "Inter_600SemiBold",
                          },
                        ]}
                      >
                        {ArticleAuthor}
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>

              <View style={styles.articleTitleContainer}>
                <Text style={styles.articleTitleText}>{ArticleTitle}</Text>
                <View style={styles.articleBottomContainer}>
                  <TouchableOpacity
                    onPress={() => pressBookmark(ArticleLink, bookmarkArticle)}
                  >
                    {bookmarks.includes(ArticleLink) ? (
                      <Image
                        source={require("../assets/clickedBookmark.png")}
                        style={styles.bookmarkImage}
                      />
                    ) : (
                      <Image
                        source={require("../assets/clearBookmark.png")}
                        style={styles.bookmarkImage}
                      />
                    )}
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => updateSearch(ArticleCategory)}
                  >
                    <Text style={styles.categoryText}>{ArticleCategory}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View style={styles.imageInnerContainer}>
              <Image source={ArticleImage} style={styles.image} />
            </View>
          </TouchableOpacity>
        )}
      </Hoverable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingVertical: 10,
  },
  innerContainer: {
    padding: 15,
    flexDirection: "row",
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  articleContentContainer: { width: "75%" },
  articleBottomContainer: {
    paddingTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  bookmarkImage: { height: 12, width: 15 },
  categoryText: {
    marginLeft: 15,
    backgroundColor: colors.greyCancel,
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 100,
    fontFamily: "Inter_600SemiBold",
    color: colors.greyLightFont,
    fontSize: 12,
  },
  articleAuthorImageContainer: {
    width: 25,
    height: 25,
    borderRadius: 100,
    backgroundColor: "white",
  },
  image: { width: 90, height: 90, borderRadius: 10 },
  imageInnerContainer: {
    justifyContent: "center",
    width: "30%",
    height: "100%",
    alignItems: "center",
  },
  articleTitleText: {
    fontSize: 16,
    fontFamily: "Inter_600SemiBold",
    width: "100%",
  },
  articleTitleContainer: {
    width: "100%",
    marginTop: 10,
    paddingRight: 10,
    justifyContent: "center",
  },
  articleAuthorText: {
    fontFamily: "Inter_600SemiBold",
    fontSize: 11,
    paddingLeft: 10,
    color: colors.greyDark,
  },
  articleAuthorImage: { height: "100%", width: "100%", borderRadius: 100 },
  articleAuthorContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
  },
});
