import React, { useState, useEffect } from "react";
import { TextInput, Text, View, TouchableOpacity } from "react-native";
import { ENV_ENCR_KEY } from "@env";

var cryptorjs = require("cryptorjs");
var myCryptor = new cryptorjs(ENV_ENCR_KEY);

export default function Password({ navigation }) {
  const [text, setText] = useState("");
  const beta = "a34a41411171";

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <View
        style={{
          backgroundColor: "#f8fafc",
          borderRadius: 5,
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontWeight: "bold", fontSize: 21, paddingBottom: 30 }}>
          Beta
        </Text>
        <TextInput
          style={{
            height: 40,
            width: 250,
            margin: 12,
            borderWidth: 1,
            borderColor: "grey",
            padding: 10,
          }}
          placeholder={"Enter password"}
          onChangeText={(input) => setText(myCryptor.encode(input))}
          //  secureTextEntry
          value={text.slice(0, myCryptor.decode(text).length)}
        />
        <TouchableOpacity
          onPress={() =>
            text == beta ? navigation.navigate("NewsVues") : setText("")
          }
          style={{
            backgroundColor: "black",
            borderRadius: 100,
            height: 40,
            width: 100,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text style={{ color: "white" }}>Submit</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
