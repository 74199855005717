import React, { useState, useContext, useEffect, useRef } from "react";
import { ScrollView, Text, View } from "react-native";

import { colors } from "../Styles/colors.js";

export default function Privacy({ navigation }) {
  const auth = getAuth();

  return (
    <ScrollView
      //  stickyHeaderIndices={[0]}
      //  stickyHeaderHiddenOnScroll={true}
      style={{ backgroundColor: "white", width: "100%" }}
    >
      <View style={{ backgroundColor: colors.greySidebar, width: "100%" }}>
        <View //intro
          style={{
            height: "40%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              color: "black",
              fontFamily: "Inter_600SemiBold",
              zIndex: 2,
            }}
          >
            Privacy
          </Text>
          <Text
            style={{
              fontFamily: "Inter_800ExtraBold",
              fontSize: width < 530 ? 50 : width < 700 ? 60 : 70,
              color: "black",
              textAlign: "center",
              zIndex: 2,
              paddingTop: 30,
              paddingHorizontal: 15,
            }}
          >
            Privacy Policy
          </Text>
          <Text
            style={{
              fontSize: 16,
              color: colors.greyDark,
              fontFamily: "Inter_600SemiBold",
              zIndex: 2,
              textAlign: "center",
              paddingTop: 30,
            }}
          >
            Updated January 03 2023
          </Text>
        </View>
        <View //contents
          style={{
            backgroundColor: "white",
            paddingVertical: "5%",
            paddingHorizontal: "10%",
          }}
        >
          <Text
            style={{
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            This privacy policy ("Policy") describes how AmpLabel.io
            ("AmpLabel", "we", "us", or "our") collects, uses, and shares
            personal information of users ("you" or "your") of the AmpLabel.io
            website and any associated services (the "Service"). By using the
            Service, you agree to the collection, use, and sharing of your
            personal information as described in this Policy. If you do not
            agree with our policies and practices, do not use the Service.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Information We Collect
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We collect the following types of information from you when you use
            the Service:
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Personal Information: When you create an account on the Service,
            we collect personal information from you such as your email address.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Usage Information: We collect information about your use of the
            Service, such as the features you use and the actions you take
            within the Service.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Cookies: We use cookies and similar tracking technologies to track
            the activity on our Service and hold certain information. Cookies
            are files with small amount of data which may include various
            identifiers. Other analytics tools and tracking technologies that
            may be used include beacons, tags, and scripts to collect and track
            information and to improve and analyze our Service. You can instruct
            your browser to refuse cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Credit Card Information: If you choose to make a purchase through
            the Service, we collect credit card information to process your
            payment. This includes Personal Information such as your name.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We do not store images that are uploaded to the Service on servers.
            Additionally, machine learning models ("models" or "AMP Models")
            created or trained using the Service are not saved on servers.
            Unless downloaded by you, images that you have uploaded and models
            that you have created or uploaded are deleted immediately after you
            exit your session. Exiting the session may include closing your
            browser or exiting the Service's Editor page.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            How We Use Your Information
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We use the information we collect from you to provide, maintain, and
            improve the Service, and to communicate with you about your use of
            the Service. We may also use your information for the following
            purposes:
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To personalize your experience: We use your information to
            personalize the Service and to provide you with a more customized
            experience.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To provide customer support: We use the information we collect to
            provide customer care and support. This may include notifying you
            about changes to our Service.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To improve the service: We use the information we collect to
            provide analysis or valuable information so that we can improve the
            Service.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To resolve technical issues: We use the information we collect to
            detect, prevent and address technical issues.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To show you ads: We use the information we collect to show you ads
            that are relevant to your interests.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - To process payments: If you make a purchase through the Service,
            we use your credit card information to process your payment.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Sharing Your Information
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We may share your information with third parties for the following
            purposes:
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - With Service Providers: We may share your information with
            third-party service providers who provide services on our behalf,
            such as payment processing and website hosting.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - With Business Partners: We may share your information with our
            business partners who provide services on our behalf. This may
            include transfer of the Service to business partners or
            third-parties who aquire the Service.
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - As Required by Law: We may disclose your information if required
            to do so by law or in response to a valid request from a government
            authority. This includes scenarios where we believe that sharing is
            necessary to protect the rights or safety of others, or to prevent
            harm.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Information we collect from third parties:
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We may receive information about you from third-party sources, such
            as Google Ads.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Security of your information:
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We take reasonable measures to protect the information we collect
            about you from loss, theft, misuse, and unauthorized access,
            disclosure, alteration, and destruction. However, no security
            measures are perfect, and we cannot guarantee the security of your
            information.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Changes to this Privacy Policy:
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            We may update our Privacy Policy. Any changes to our Privacy Policy
            will be reflected on this page.
          </Text>
        </View>
      </View>
    </ScrollView>
  );
}
