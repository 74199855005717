import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import News from "./Pages/News";
import Privacy from "./Pages/Privacy";
import TermsConditions from "./Pages/TermsConditions";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import Password from "./Pages/Password";

import Error from "./Pages/Error";

import { MenuProvider } from "react-native-popup-menu";
import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/Inter";

const Stack = createNativeStackNavigator();

const config = {
  screens: {
    News: "news",
    Password: "password",
    Privacy: "privacy",
    Error: "*",
    TermsConditions: "terms_and_conditions",
  },
};

const linking = {
  // prefixes: ['*newsvues.com/*', '*localhost:19006/*'],
  config,
};

const App = () => {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MenuProvider style={{ borderRadius: 20 }}>
          <NavigationContainer>
            <Stack.Navigator
              screenOptions={{
                headerShown: false,
              }}
            >
              <Stack.Screen name="NewsVues" component={News} />
              <Stack.Screen name="Password" component={Password} />
              <Stack.Screen name="Privacy" component={Privacy} />
              <Stack.Screen name="Error" component={Error} />
              <Stack.Screen
                name="TermsConditions"
                component={TermsConditions}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </MenuProvider>
      </PersistGate>
    </Provider>
  );
};
export default App;
