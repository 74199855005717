import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import { colors } from "../Styles/colors";
import { Hoverable, Pressable } from "react-native-web-hover";

export default function ReadArticleItem({ item, clickedSearch }) {
  //Get the read articles from redux
  const currentReadArticles = useSelector((state) => state.read.read);

  return (
    <Hoverable style={styles.container}>
      {({ hovered }) => (
        <TouchableOpacity
          style={[
            styles.innerContainer,
            { backgroundColor: hovered && "#e7f2fd" },
          ]}
          onPress={() => clickedSearch(currentReadArticles[item])}
        >
          <Text
            numberOfLines={1}
            style={[
              styles.text,
              { color: hovered ? colors.blueLabelBorder : colors.greyDark },
            ]}
          >
            {currentReadArticles[item]}
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  container: { width: "80%", alignSelf: "center", marginBottom: 5 },
  innerContainer: {
    paddingVertical: 5,
    alignItems: "center",
    borderRadius: 5,
    paddingLeft: 15,
    flexDirection: "row",
  },
  text: {
    fontFamily: "Inter_600SemiBold",
    fontSize: 13,
  },
});
