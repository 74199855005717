import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { useSelector } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { colors } from "../Styles/colors";
import { Hoverable, Pressable } from "react-native-web-hover";

export default function SearchHistoryItem({ item, clickedSearch }) {
  //Get the saved searches from redux
  const currentSavedSearches = useSelector(
    (state) => state.searches.searchHistory
  );

  return (
    <Hoverable style={styles.container}>
      {({ hovered }) => (
        <TouchableOpacity
          style={[
            styles.innerContainer,
            { backgroundColor: hovered && "#e7f2fd" },
          ]}
          onPress={() => clickedSearch(currentSavedSearches[item])}
        >
          <Feather
            name="search"
            size={21}
            color={hovered ? colors.blueLabelBorder : colors.greyDark}
            style={styles.searchIcon}
          />
          <Text
            numberOfLines={1}
            style={[
              styles.text,
              { color: hovered ? colors.blueLabelBorder : colors.greyDark },
            ]}
          >
            {currentSavedSearches[item]}
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
}

const styles = StyleSheet.create({
  container: { width: "80%", alignSelf: "center", marginBottom: 5 },
  innerContainer: {
    paddingVertical: 5,
    alignItems: "center",
    borderRadius: 5,
    paddingLeft: 15,
    flexDirection: "row",
  },
  searchIcon: { paddingRight: 10 },
  text: {
    fontFamily: "Inter_600SemiBold",
    fontSize: 14,
  },
});
