export const CategoriesData = [
  {
    Index: 1,
    Category: "💼 Business",
  },
  {
    Index: 2,
    Category: "🎥 Entertainment",
  },
  {
    Index: 3,
    Category: "🌏 Global News",
  },
  {
    Index: 4,
    Category: "🏥 Health",
  },
  {
    Index: 5,
    Category: "🏛 Politics",
  },
  {
    Index: 6,
    Category: "🧪 Science",
  },
  {
    Index: 7,
    Category: "🏅 Sports",
  },
  {
    Index: 8,
    Category: "🤖 Technology",
  },
  {
    Index: 9,
    Category: "🇺🇸 US News",
  },
];
