import React, { useState, useContext, useEffect, useRef } from "react";
import { ScrollView, Text, View } from "react-native";

export default function TermsConditions({ navigation }) {
  return (
    <ScrollView
      //  stickyHeaderIndices={[0]}
      //  stickyHeaderHiddenOnScroll={true}
      style={{ backgroundColor: "white", width: width }}
    >
      <View style={{ backgroundColor: colors.greySidebar, width: width }}>
        <View //intro
          style={{
            height: height * 0.4,
            width: width,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              color: colors.greyDark,
              fontFamily: "Inter_600SemiBold",
              zIndex: 2,
            }}
          >
            Terms & Conditions
          </Text>
          <Text
            style={{
              fontFamily: "Inter_800ExtraBold",
              fontSize: width < 530 ? 50 : width < 700 ? 60 : 70,
              color: "black",
              textAlign: "center",
              zIndex: 2,
              paddingTop: 30,
            }}
          >
            Terms of Service
          </Text>
          <Text
            style={{
              fontSize: 16,
              color: colors.greyDark,
              fontFamily: "Inter_600SemiBold",
              zIndex: 2,
              textAlign: "center",
              paddingTop: 30,
            }}
          >
            Updated January 03 2023
          </Text>
        </View>
        <View //contents
          style={{
            backgroundColor: "white",
            paddingVertical: "5%",
            paddingHorizontal: "10%",
          }}
        >
          <Text
            style={{
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            By using our website and digital subscription Services, you are
            agreeing to comply with and be bound by the following terms and
            conditions of use, which together with our Privacy Policy govern
            AmpLabel's relationship with you in relation to this website and
            Service. If you disagree with any part of these terms and
            conditions, please do not use our website or Service in any manner.
          </Text>
          <Text
            style={{
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
              paddingTop: 30,
            }}
          >
            The term 'AmpLabel' or 'AmpLabel.IO' or 'AmpLabel, LLC' or 'us' or
            'we' refers to the owner of the website and Service. The term 'you'
            refers to the user or viewer of our website and Service.
          </Text>
          <Text
            style={{
              color: colors.greyDark,
              fontSize: 16,
              paddingTop: 30,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            These Terms of Service (the “Terms”) are a binding contract between
            you and AmpLabel. You must agree to and accept all of the Terms, or
            you don’t have the right to use the Services. Your using the
            Services in any way means that you agree to all of these Terms, and
            these Terms will remain in effect while you use the Services. These
            Terms include the provisions in this document, as well as those in
            the Privacy Policy.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Use of the Service
          </Text>
          <Text
            style={{
              color: colors.greyDark,
              fontSize: 16,
              paddingTop: 30,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            By using the Service, you represent, warrant, and agree that you
            will not use the Service or interact with the Service in a manner
            that:
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Infringes or violates the intellectual property rights or any
            other rights of anyone else (including AmpLabel);
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Violates any law or regulation, including, without limitation, any
            applicable export control laws;
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Is harmful, fraudulent, deceptive, threatening, harassing,
            defamatory, obscene, or otherwise objectionable;
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Jeopardizes the security of your AmpLabel account or anyone else’s
            (such as allowing someone else to log in to the Services as you);
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Attempts, in any manner, to obtain the password, account, or other
            security information from any other user;
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Violates the security of any computer network, or cracks any
            passwords or security encryption codes;
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </Text>
          <Text
            style={{
              paddingLeft: 30,
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            - Decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services.
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            A violation of any of the foregoing is grounds for termination of
            your right to use or access the Services.
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            You are responsible for all Content you contribute, in any manner,
            to the Services, and you represent and warrant all rights necessary
            to do so, in the manner in which you contribute it. You are
            responsible for all your activity in connection with the Services.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Third-parties
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            The Services may contain links or connections to third-party
            websites or services that are not owned or controlled by AmpLabel.
            When you access third party websites or use third-party services,
            you accept that there are risks in doing so and that AmpLabel is not
            responsible for such risks. We encourage you to be aware when you
            leave the Services or use third-party servises and to read the Terms
            and Conditions and Privacy Notice of each third-party website or
            service that you visit or utilize.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            AmpLabel has no control over and assumes no responsibility for, the
            content, accuracy, privacy policies, or practices of, or opinions
            expressed in any third-party websites or by any third party that you
            interact with through the Services. In addition, AmpLabel will not
            and cannot monitor, verify, censor, or edit the content of any
            third-party site or service. By using the Services, you release and
            hold us harmless from any and all liability arising from your use of
            any third-party website or service.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Subscription and Billing
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            Our digital subscription Service is billed either monthly or
            annually. You will be billed in advance on a recurring basis
            according to the subscription plan you choose. Subscriptions of the
            Service will continue to renew until cancelled. All subscription
            fees are non-refundable. If you change your subscription plan, you
            may be eligible for a credit to your account.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            If you cancel your subscription, it will remain active until the end
            of the subscription term but will not renew.
          </Text>

          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Content and Intellectual Property
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            All Content included on this website and Service, such as text,
            data, graphics, logos, images, and software (collectively referred
            to as the "Content"), is the property of AmpLabel or its Content
            suppliers and protected by international copyright and trademark
            laws. The compilation of all Content on this website and Service is
            the exclusive property of AmpLabel and protected by international
            copyright and trademark laws. You may not use any Content on this
            website or Service for any commercial purpose without the express
            written consent of AmpLabel. You may not reproduce, copy,
            distribute, transmit, display, publish, sell, license, or create
            derivative works from the Content on this website or Service without
            the express written consent of AmpLabel.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            You understand that the Services are owned by AmpLabel. You won’t
            modify, publish, transmit, participate in the transfer or sale of,
            reproduce (except as expressly provided in this Section), or
            otherwise exploit any of the Services.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            Image data uploaded or labeled using our Service remains the
            property of the user. AmpLabel does not claim ownership of any
            user-generated Content.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Governing Law
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            These terms and conditions are governed by and construed in
            accordance with the laws of the United States of America and you
            irrevocably submit to the exclusive jurisdiction of the United
            States courts. By using this Service, you are agreeing to comply
            with the laws of the Unites States.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Liability
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            The Content on this website and Service is provided on an 'as is'
            basis. AmpLabel makes no warranties, expressed or implied, and
            hereby disclaims and negates all other warranties, including without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights. In no event
            shall AmpLabel be liable for any damages (including, without
            limitation, damages for loss of data or profit, or due to business
            interruption) arising out of the use or inability to use the
            materials on this website or Service, even if AmpLabel or an
            authorized representative has been notified orally or in writing of
            the possibility of such damage.
          </Text>

          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Modification of Terms and Conditions
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            AmpLabel reserves the right to modify these terms and conditions at
            any time. You are responsible for regularly reviewing these terms
            and conditions. Your continued use of this website and Service after
            any changes are made constitutes your acceptance of the modified
            terms and conditions.
          </Text>
          <Text
            style={{
              paddingTop: 30,
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              lineHeight: 40,
            }}
          >
            Contact
          </Text>
          <Text
            style={{
              paddingTop: 10,
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_400Regular",
              lineHeight: 40,
            }}
          >
            If you have any questions about these terms and conditions, please
            contact us at support@amplabel.io.
          </Text>
        </View>
      </View>
    </ScrollView>
  );
}
