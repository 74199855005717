import React from "react";
import { StyleSheet, Text, Image, TouchableOpacity } from "react-native";

export default function SearchSourceItem({
  item,
  clickedSource,
  setPressedSources,
}) {
  //Passing in the source data props
  const ArticleAuthor = item.ArticleAuthor;
  const SourceImage = item.SourceImage;

  return (
    <TouchableOpacity
      onPress={() => (clickedSource(ArticleAuthor), setPressedSources(false))}
      style={styles.container}
    >
      <Text style={styles.sourceName}>{ArticleAuthor}</Text>
      <Image source={SourceImage} style={styles.sourceImage} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 150,
    height: 105,
    borderRadius: 10,
    margin: 5,
  },
  sourceName: {
    position: "absolute",
    zIndex: 2,
    top: 15,
    left: 15,
    color: "white",
    fontFamily: "Inter_700Bold",
    fontSize: 18,
    paddingRight: 55,
  },
  sourceImage: { width: "100%", height: "100%", borderRadius: 10 },
});
