export const SearchSourceData = [
  {
    ArticleAuthor: "NYTimes",
    SourceImage: require("../assets/SearchSourceAssets/NYTimes.png"),
  },
  {
    ArticleAuthor: "CNN",
    SourceImage: require("../assets/SearchSourceAssets/CNN.png"),
  },
  {
    ArticleAuthor: "CBS News",
    SourceImage: require("../assets/SearchSourceAssets/CBS.png"),
  },
  {
    ArticleAuthor: "PBS NewsHour",
    SourceImage: require("../assets/SearchSourceAssets/PBS_NewsHour.png"),
  },
  {
    ArticleAuthor: "TechCrunch",
    SourceImage: require("../assets/SearchSourceAssets/TechCrunch.png"),
  },
  {
    ArticleAuthor: "The Verge",
    SourceImage: require("../assets/SearchSourceAssets/TheVerge.png"),
  },
  {
    ArticleAuthor: "The Guardian",
    SourceImage: require("../assets/SearchSourceAssets/TheGuardian.png"),
  },
  {
    ArticleAuthor: "Buzzfeed News",
    SourceImage: require("../assets/SearchSourceAssets/BuzzfeedNews.png"),
  },
  {
    ArticleAuthor: "US Weekly",
    SourceImage: require("../assets/SearchSourceAssets/USWeekly.png"),
  },
  {
    ArticleAuthor: "Huffington Post",
    SourceImage: require("../assets/SearchSourceAssets/HuffingtonPost.png"),
  },
  {
    ArticleAuthor: "Vox",
    SourceImage: require("../assets/SearchSourceAssets/Vox.png"),
  },
  {
    ArticleAuthor: "LA Times",
    SourceImage: require("../assets/SearchSourceAssets/LATimes.png"),
  },
  {
    ArticleAuthor: "NBC News",
    SourceImage: require("../assets/SearchSourceAssets/NBCNews.png"),
  },
  {
    ArticleAuthor: "USA Today",
    SourceImage: require("../assets/SearchSourceAssets/USAToday.png"),
  },
  {
    ArticleAuthor: "Refinery29",
    SourceImage: require("../assets/SearchSourceAssets/Refinery29.png"),
  },
  {
    ArticleAuthor: "Vanity Fair",
    SourceImage: require("../assets/SearchSourceAssets/VanityFair.png"),
  },
  {
    ArticleAuthor: "BBC",
    SourceImage: require("../assets/SearchSourceAssets/BBC.png"),
  },
  {
    ArticleAuthor: "Washington Post",
    SourceImage: require("../assets/SearchSourceAssets/WashingtonPost.png"),
  },
];
