import {
  SET_BOOKMARK,
  SET_CATEGORY,
  SET_SEARCH_HISTORY,
  SET_ARTICLES,
  SET_READ,
} from "./constants";
export function reduxBookmarks(bookmarkArticle) {
  return {
    type: SET_BOOKMARK,
    bookmarkArticle,
  };
}
export function reduxCategories(categorySelections) {
  return {
    type: SET_CATEGORY,
    categorySelections,
  };
}
export function reduxSearchHistory(searchHistory) {
  return {
    type: SET_SEARCH_HISTORY,
    searchHistory,
  };
}
export function reduxArticles(articles) {
  return {
    type: SET_ARTICLES,
    articles,
  };
}
export function reduxRead(read) {
  return {
    type: SET_READ,
    read,
  };
}
