import React, { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { colors } from "../Styles/colors.js";

import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/Inter";
import { useDispatch, useSelector } from "react-redux";
import { reduxCategories } from "../redux/actions.js";
export default function CategoriesItem({ item }) {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  const [toggle, setToggle] = useState(false);

  //Define dispatch for redux
  const dispatch = useDispatch();

  //Get the selected news categories from redux
  const currentCategoriesSelection = useSelector(
    (state) => state.category.categorySelections
  );

  //Set the toggles on load
  useEffect(() => {
    //If there are categories selected, turn on the toggle
    try {
      currentCategoriesSelection.includes(item.Category) && setToggle(true);
    } catch {
      //...If there are not categories selected, don't change the toggle
      setToggle(toggle);
    }
  }, []);

  //Define the function to set the categories (used with redux)
  const categoryToggled = () => {
    //Get the current categories selected
    const allCategories = [];
    const currentCategoriesListOrNot =
      currentCategoriesSelection != null ? currentCategoriesSelection : [];
    for (var i = 0; i < currentCategoriesListOrNot.length; i++) {
      allCategories.push(currentCategoriesSelection[i]);
    }
    //If this toggle isn't active, add the category
    !toggle && allCategories.push(item.Category);

    //If this toggle is active, remove the category

    for (var i = 0; i < allCategories.length; i++) {
      if (allCategories[i] == item.Category) {
        toggle && allCategories.splice(i, 1);
        break;
      }
    }
    dispatch(reduxCategories(allCategories));
    setToggle(!toggle);
  };

  return (
    <TouchableOpacity
      style={[
        {
          borderWidth: toggle ? 1.5 : 1,
          borderColor: toggle ? "black" : colors.greyBorder,
        },
        styles.container,
      ]}
      onPress={() => categoryToggled()}
    >
      <Text
        style={{
          color: toggle ? "black" : colors.greyDark,
          fontFamily: toggle ? "Inter_800ExtraBold" : "Inter_600SemiBold",
        }}
      >
        {item.Category}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: 140,
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 10,
  },
});
