import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import { colors } from "../Styles/colors.js";
import {
  useFonts,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/Inter";
export default function MobileNewsItems({
  item,
  updateSearch,
  bookmarked,
  pressBookmark,
  saveRead,
}) {
  let [fontsLoaded] = useFonts({
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  //Passing in the article props
  const ArticleAuthorIcon = item.ArticleAuthorIcon;
  const ArticleAuthor = item.ArticleAuthor;
  const ArticleTitle = item.ArticleTitle;
  const ArticleCategory = item.ArticleCategory;
  const ArticleStart = item.ArticleStart;
  const ArticleContent = item.ArticleContent;
  const ArticleImage = item.ArticleImage;
  const ArticleLink = item.ArticleLink;

  //Consolidate all of the article items to pass into redux
  const bookmarkArticle = {
    ArticleAuthorIcon: ArticleAuthorIcon,
    ArticleAuthor: ArticleAuthor,
    ArticleTitle: ArticleTitle,
    ArticleCategory: ArticleCategory,
    ArticleStart: ArticleStart,
    ArticleContent: ArticleContent,
    ArticleImage: ArticleImage,
    ArticleLink: ArticleLink,
  };

  //Bolds the first word (ArticleStart) of the article content
  const ArticleText = (props) => {
    const text = props.text.split(" ");
    return (
      <Text style={styles.articleText}>
        {text.map((text) => {
          if (text == props.ArticleStart) {
            return <Text style={styles.articleFirstWord}>{text}</Text>;
          }
          return `${text} `;
        })}
      </Text>
    );
  };
  const [bookmarks, setBookmarks] = useState([]);

  //set the bookmarks
  useEffect(() => {
    setBookmarks(bookmarked);
    //console.log(bookmarked);
  }, [bookmarked]);

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <Image
          source={ArticleImage}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </View>

      <View style={styles.articleContainer}>
        <View style={styles.articleTopContainer}>
          <View style={styles.articleAuthorContainer}>
            <TouchableOpacity onPress={() => updateSearch(ArticleAuthor)}>
              <View style={styles.articleAuthorImageContainer}>
                <Image
                  source={ArticleAuthorIcon}
                  style={styles.articleAuthorImage}
                />
              </View>
            </TouchableOpacity>
            <Hoverable>
              {({ hoveredAuthor }) => (
                <TouchableOpacity onPress={() => updateSearch(ArticleAuthor)}>
                  <Text
                    style={[
                      styles.articleAuthorText,
                      {
                        fontFamily: hoveredAuthor
                          ? "Inter_800SemiBlack"
                          : "Inter_600SemiBold",
                      },
                    ]}
                  >
                    {ArticleAuthor}
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>
          <View style={styles.articleTopRightContainer}>
            <TouchableOpacity onPress={() => updateSearch(ArticleCategory)}>
              <Text style={styles.categoryText}>{ArticleCategory}</Text>
            </TouchableOpacity>
            <View style={styles.articleTopRightContainerSpacer} />
            <TouchableOpacity
              onPress={() => pressBookmark(ArticleLink, bookmarkArticle)}
            >
              {bookmarks.includes(ArticleLink) ? (
                <Image
                  source={require("../assets/clickedBookmark.png")}
                  style={styles.bookmarkImage}
                />
              ) : (
                <Image
                  source={require("../assets/clearBookmark.png")}
                  style={styles.bookmarkImage}
                />
              )}
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.articleTitleContainer}>
          <Text style={styles.articleTitleText}>{ArticleTitle}</Text>
          <View style={styles.articleTextContainer}>
            <ArticleText
              ArticleStart={ArticleStart}
              text={ArticleStart + "  " + ArticleContent}
            />
          </View>
          <View style={styles.visitSiteContainer}>
            <TouchableOpacity
              onPress={() => saveRead(ArticleTitle, ArticleLink)}
              style={styles.visitSiteButton}
            >
              <Text style={styles.visitSiteButtonText}>
                📰 Read the Full Article
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 15,
    backgroundColor: colors.greyCancel,
  },
  articleContainer: {
    width: "100%",
    paddingHorizontal: 15,
    backgroundColor: "white",
    borderBottomWidth: 1,
    borderColor: colors.greyBorder,
  },
  articleTopContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 20,
  },
  articleTopRightContainer: { flexDirection: "row", alignItems: "center" },
  articleTopRightContainerSpacer: { width: 10 },
  bookmarkImage: { height: 17, width: 21 },
  articleAuthorImageContainer: {
    width: 25,
    height: 25,
    borderRadius: 100,
    backgroundColor: "white",
  },
  imageContainer: { height: 200, width: "100%" },
  image: { width: 90, height: 90, borderRadius: 10 },

  articleTitleText: {
    fontSize: 17,
    fontFamily: "Inter_700Bold",
    paddingVertical: 10,
    width: "100%",
  },
  articleTitleContainer: {
    width: "100%",
    marginTop: 10,
    justifyContent: "center",
  },
  articleTextContainer: { paddingVertical: 15 },
  articleText: {
    fontSize: 15,
    fontFamily: "Inter_400Regular",
    width: "100%",
    lineHeight: 20,
    color: "black",
  },
  articleFirstWord: { fontFamily: "Inter_700Bold", fontSize: 15 },
  articleAuthorText: {
    fontFamily: "Inter_600SemiBold",
    fontSize: 11,
    paddingLeft: 10,
    color: colors.greyDark,
  },
  articleAuthorImage: { height: "100%", width: "100%", borderRadius: 100 },
  articleAuthorContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
  },
  categoryText: {
    marginLeft: 15,
    backgroundColor: colors.greyCancel,
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 100,
    fontFamily: "Inter_600SemiBold",
    color: colors.greyLightFont,
    fontSize: 12,
  },
  visitSiteContainer: {
    backgroundColor: colors.greyCancel,
    height: 40,
    width: 200,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginVertical: 20,
    marginBottom: 30,
  },
  visitSiteButton: {
    height: 40,
    width: 200,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  visitSiteButtonText: {
    color: colors.greyDark,
    fontFamily: "Inter_600SemiBold",
    fontSize: 16,
  },
});
