import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import {
  ENV_API_KEY,
  ENV_AUTH_DOMAIN,
  ENV_PROJECT_ID,
  ENV_STORAGE_BUCKET,
  ENV_MESSAGING_SENDER_ID,
  ENV_APP_ID,
} from "@env";

const firebaseConfig = {
  apiKey: ENV_API_KEY,
  authDomain: ENV_AUTH_DOMAIN,
  projectId: ENV_PROJECT_ID,
  storageBucket: ENV_STORAGE_BUCKET,
  messagingSenderId: ENV_MESSAGING_SENDER_ID,
  appId: ENV_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
