import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  Dimensions,
  Image,
  FlatList,
  ActivityIndicator,
  Animated,
  ScrollView,
} from "react-native";
import { colors } from "../Styles/colors.js";
import { Hoverable, Pressable } from "react-native-web-hover";
import { Feather, Ionicons } from "@expo/vector-icons";
import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import NewsItems from "../Components/NewsItems";
import { CategoriesData } from "../Data/CategoriesData";
import { SearchSourceData } from "../Data/SearchSourceData";
import SearchHistoryItem from "../Components/SearchHistoryItem";
import SearchSourceItem from "../Components/SearchSourceItem";
import ReadArticleItem from "../Components/ReadArticleItem";
import { Input, Overlay } from "react-native-elements";
import * as WebBrowser from "expo-web-browser";
import {
  reduxSearchHistory,
  reduxRead,
  reduxBookmarks,
} from "../redux/actions";
import CategoriesItem from "../Components/CategoriesItem.js";
import { useDispatch, useSelector } from "react-redux";
import { deviceType } from "react-device-detect";
import * as Animatable from "react-native-animatable";
import MobileNewsItems from "../Components/MobileNewsItems.js";
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/Inter";
import { TouchableOpacity } from "react-native-gesture-handler";

export default function News({ navigation }) {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  const [search, setSearch] = useState("");
  const [sourceSearch, setSourceSearch] = useState("");
  const [sourceSearchData, setSourceSearchData] = useState([]);
  const [newsStories, setNewsStories] = useState([]);
  const [searchArticleData, setSearchArticleData] = useState([]);
  const [filteredNewsData, setFilteredNewsData] = useState([]);
  const [selectedStoryAuthor, setSelectedStoryAuthor] = useState("");
  const [selectedStoryTitle, setSelectedStoryTitle] = useState("");
  const [selectedStoryAuthorIcon, setSelectedStoryAuthorIcon] = useState("");
  const [selectedStoryArticleCategory, setSelectedStoryArticleCategory] =
    useState("");
  const [selectedStoryImage, setSelectedStoryImage] = useState("");
  const [selectedStoryArticleStart, setSelectedStoryArticleStart] =
    useState("");
  const [selectedStoryArticleContent, setSelectedStoryArticleContent] =
    useState("");
  const [selectedStoryArticleLink, setSelectedStoryArticleLink] = useState("");
  const [pressedCategories, setPressedCategories] = useState(false);
  const [pressedSources, setPressedSources] = useState(false);
  const [triggerSavedSearchRefresh, setTriggerSavedSearchRefresh] =
    useState(false);
  const [triggerReadArticles, setTriggerReadArticles] = useState(false);
  const [bookmarked, setBookmarked] = useState([]);
  const [showBookmarks, setShowBookmarks] = useState(false);
  const [bookmarkButtonHovering, setBookmarkButtonHovering] = useState(false);
  const [scrollRefresh, setScrollRefresh] = useState(false);
  const [fetchingStories, setFetchingStories] = useState(false);
  const [tabletShowSidebar, setTabletShowSidebar] = useState(false);
  const [mobileShowSidebar, setMobileShowSidebar] = useState(false);
  const [mobileShowSearch, setMobileShowSearch] = useState(false);
  const [hidePromo, setHidePromo] = useState(false); //set to false
  const [lastVisibleData, setLastVisibleData] = useState("");
  const width = Dimensions.get("window").width;
  const height = Dimensions.get("window").height;

  //-------------------------------------------//
  //                   Redux                   //
  //-------------------------------------------//
  //Define dispatch for redux
  const dispatch = useDispatch();

  //Get the saved searches from redux
  const currentSavedSearches = useSelector(
    (state) => state.searches.searchHistory
  );

  //Get the read articles from redux
  const currentReadArticles = useSelector((state) => state.read.read);

  //Get the selected news categories from redux
  const currentCategoriesSelection = useSelector(
    (state) => state.category.categorySelections
  );

  //-------------------------------------------//
  //                 Bookmarks                 //
  //-------------------------------------------//
  //Consolidate all of the article items to pass into redux
  const bookmarkArticle = {
    ArticleAuthorIcon: selectedStoryAuthorIcon,
    ArticleAuthor: selectedStoryAuthor,
    ArticleTitle: selectedStoryTitle,
    ArticleCategory: selectedStoryArticleCategory,
    ArticleStart: selectedStoryArticleStart,
    ArticleContent: selectedStoryArticleContent,
    ArticleImage: selectedStoryImage,
    ArticleLink: selectedStoryArticleLink,
  };

  //Function when bookmark is pressed
  const pressBookmark = (link, articleContents) => {
    //Create list for all the bookmarks
    console.log("Link Pressed: ", link);
    const allBookmarks = [];
    const currentBookmarksListOrNot =
      currentBookmarks != null ? currentBookmarks : [];
    for (var i = 0; i < currentBookmarksListOrNot.length; i++) {
      allBookmarks.push(currentBookmarks[i]);
    }

    //If not bookmarked, push the bookmark to state (front of list so it appears first)
    !bookmarked.includes(link) && allBookmarks.unshift(articleContents);

    //If it is bookmarked, remove article from state
    for (var i = 0; i < allBookmarks.length; i++) {
      if (allBookmarks[i].ArticleLink == link) {
        bookmarked.includes(link) && allBookmarks.splice(i, 1);
        break;
      }
    }
    dispatch(reduxBookmarks(allBookmarks));

    //set state of all the bookmarked articles for the bookmark icon
    const allBookmarkedArticles = [];
    for (var i = 0; i < allBookmarks.length; i++) {
      allBookmarkedArticles.push(allBookmarks[i].ArticleLink);
    }
    setBookmarked(allBookmarkedArticles);
  };

  //Get the bookmarked stories from redux
  const currentBookmarks = useSelector(
    (state) => state.bookmark.bookmarkArticle
  );

  //Set initial state of all the bookmarked articles for the bookmark icon
  useEffect(() => {
    const allBookmarkedArticles = [];
    try {
      for (var i = 0; i < currentBookmarks?.length; i++) {
        allBookmarkedArticles.push(currentBookmarks[i].ArticleLink);
      }

      setBookmarked(allBookmarkedArticles);

      //If all the stories become unbookmarked, set the 'show bookmark' state to false
      !currentBookmarks?.length > 0 && setShowBookmarks(false);
    } catch {
      setBookmarked(allBookmarkedArticles);

      //If all the stories become unbookmarked, set the 'show bookmark' state to false
      !currentBookmarks?.length > 0 && setShowBookmarks(false);
    }
  }, [currentBookmarks]);

  //-------------------------------------------//
  //                Animations                 //
  //-------------------------------------------//
  //These are used in the scrolling animation
  const scrollY = new Animated.Value(0);
  const scale = scrollY.interpolate({
    inputRange: [-50, 0],
    outputRange: [1.05, 1],
    extrapolate: "clamp",
  });
  const opacity = scrollY.interpolate({
    inputRange: [0, 25],
    outputRange: [1, 0],
  });

  //-------------------------------------------//
  //                  Search                   //
  //-------------------------------------------//
  //Delete search history
  const clearRecentSearch = () => {
    dispatch(reduxSearchHistory([]));
    setTriggerSavedSearchRefresh(!triggerSavedSearchRefresh);
  };

  //Save the searches when search editing finishes
  const saveSearches = (search) => {
    const savedSearchHistory =
      currentSavedSearches?.length > 0 ? currentSavedSearches : [];

    search != "" && savedSearchHistory.unshift(search);
    const removeDupSearches = [...new Set(savedSearchHistory)];
    dispatch(reduxSearchHistory(removeDupSearches.slice(0, 3)));
    setTriggerSavedSearchRefresh(!triggerSavedSearchRefresh);
  };

  //Function to handle source search
  const updateSourceSearch = (text) => {
    const sources = SearchSourceData;
    const filteredData = sources.filter(function (item) {
      //Applying filter for source name in search bar
      const itemData = item.ArticleAuthor.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    setSourceSearch(text);
    text == ""
      ? setSourceSearchData(SearchSourceData)
      : setSourceSearchData(filteredData);
  };

  //Function to handle news article searches
  const updateSearch = (text) => {
    setTabletShowSidebar(false);
    setMobileShowSidebar(false);
    const allNewsStories = newsStories;
    const filteredData = allNewsStories.filter(function (item) {
      //Applying filter for all the article details in search bar
      const itemData = item.ArticleAuthor.concat(item.ArticleTitle)
        .concat(item.ArticleStart)
        .concat(item.ArticleContent)
        .concat(item.ArticleCategory)
        .toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    setSearch(text);
    text == ""
      ? setSearchArticleData(newsStories)
      : setSearchArticleData(filteredData);
  };

  //Clicking a source
  const clickedSource = (source) => {
    setSearch(source),
      updateSearch(source),
      saveSearches(source),
      flatListRef.current.scrollToIndex({ index: 0 });
  };

  //-------------------------------------------//
  //             Viewed Articles               //
  //-------------------------------------------//

  //Delete read history
  const clearRecentRead = () => {
    dispatch(reduxRead([]));
    setTriggerReadArticles(!triggerReadArticles);
  };

  //Save the read articles when user visits source site + open site
  const saveRead = (title, link) => {
    WebBrowser.openBrowserAsync(link);
    const savedRead =
      currentReadArticles?.length > 0 ? currentReadArticles : [];
    savedRead.unshift(title);
    const removeDupRead = [...new Set(savedRead)];
    dispatch(reduxRead(removeDupRead.slice(0, 3)));
    setTriggerReadArticles(!triggerReadArticles);
  };

  //pass a function to allow each news story to navigate to the full article
  const goTo = (page, site, source) => {
    navigation.navigate(page, { url: site, author: source });
  };

  //-------------------------------------------//
  //                 Refresh                   //
  //-------------------------------------------//

  //This ref is used to scroll to top when refresh is pressed
  const flatListRef = useRef();

  //fetch the data
  useEffect(() => {
    const fetchData = async () => {
      setFetchingStories(true);
      const pullData = query(
        collection(db, "Articles"),
        orderBy("DateTime"),
        startAfter(lastVisibleData != "" ? lastVisibleData : null), //if data had been fetched before, grab the next batch
        limit(7)
      );
      const documentSnapshots = await getDocs(pullData);

      //Save the last batch as a starting point for the next fetch
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setLastVisibleData(lastVisible);

      const allArticles = [];
      documentSnapshots.forEach((snapshot) => {
        let data = snapshot.data();

        allArticles.unshift({
          ArticleCategory: data.ArticleCategory,
          ArticleImage: {
            uri: data.ArticleImage,
          },
          ArticleAuthorIcon: {
            uri: data.ArticleAuthorIcon,
          },
          ArticleAuthor: data.ArticleAuthor,
          ArticleTitle: data.ArticleTitle,
          ArticleStart: data.ArticleStart,
          ArticleContent: data.ArticleContent,
          ArticleLink: data.ArticleLink,
          ArticleDate: data.DateTime,
        });
      });
      const anyCurrentStories = newsStories.concat(allArticles);
      selectedStoryAuthor == "" &&
        setSelectedStoryAuthor(allArticles[0].ArticleAuthor);
      selectedStoryTitle == "" &&
        setSelectedStoryTitle(allArticles[0].ArticleTitle);
      selectedStoryAuthorIcon == "" &&
        setSelectedStoryAuthorIcon(allArticles[0].ArticleAuthorIcon);
      selectedStoryArticleCategory == "" &&
        setSelectedStoryArticleCategory(allArticles[0].ArticleCategory);
      selectedStoryImage == "" &&
        setSelectedStoryImage(allArticles[0].ArticleImage);
      selectedStoryArticleStart == "" &&
        setSelectedStoryArticleStart(allArticles[0].ArticleStart);
      selectedStoryArticleContent == "" &&
        setSelectedStoryArticleContent(allArticles[0].ArticleContent);
      selectedStoryArticleLink == "" &&
        setSelectedStoryArticleLink(allArticles[0].ArticleLink);
      setNewsStories(anyCurrentStories);
      setFetchingStories(false);
    };
    fetchData();
  }, [scrollRefresh]);

  //Filter the news stories based on the categories
  useEffect(() => {
    const setCatgories = async () => {
      const categories = await currentCategoriesSelection;

      //Make a blank list (to catch the category-filtered articles)
      const allNewsDataList = [];

      //Create a copy of the news data (to not edit the new data directly)
      const allNewsData = newsStories;

      //Check if each news article's category is included in the selected categories
      //...If so, push to our list
      for (var i = 0; i < newsStories?.length; i++) {
        categories?.includes(allNewsData[i].ArticleCategory) &&
          allNewsDataList.push(allNewsData[i]);
      }
      //If no categories are selected, return all news stories

      categories?.length > 0
        ? setFilteredNewsData(allNewsDataList)
        : setFilteredNewsData(allNewsData);
    };
    setCatgories();
  }, [currentCategoriesSelection, newsStories]);

  //Create infinite scroll
  const infiniteScroll = () => {
    setScrollRefresh(!scrollRefresh);
  };

  //-------------------------------------------//
  //        Render Items (for Flatlists)       //
  //-------------------------------------------//

  //This renders each of the search history items
  const renderItemSearchHistory = ({ index }) => {
    return <SearchHistoryItem item={index} clickedSearch={clickedSource} />;
  };
  //This renders each of the search history items
  const renderItemReadArticles = ({ index }) => {
    return <ReadArticleItem item={index} clickedSearch={clickedSource} />;
  };

  //This renders each of the sources in the "Sources" section
  const renderItemSources = ({ item }) => {
    return (
      <SearchSourceItem
        item={item}
        clickedSource={clickedSource}
        setPressedSources={setPressedSources}
      />
    );
  };

  //This renders the News items
  const renderItemSavedStories = ({ item }) => {
    return (
      <NewsItems
        updateSearch={updateSearch}
        goTo={goTo}
        item={item}
        selectedStoryTitle={selectedStoryTitle}
        selectedStoryAuthor={selectedStoryAuthor}
        setSelectedStoryAuthor={setSelectedStoryAuthor}
        setSelectedStoryAuthorIcon={setSelectedStoryAuthorIcon}
        setSelectedStoryArticleCategory={setSelectedStoryArticleCategory}
        setSelectedStoryTitle={setSelectedStoryTitle}
        setSelectedStoryImage={setSelectedStoryImage}
        setSelectedStoryArticleStart={setSelectedStoryArticleStart}
        setSelectedStoryArticleContent={setSelectedStoryArticleContent}
        setSelectedStoryArticleLink={setSelectedStoryArticleLink}
        bookmarked={bookmarked}
        pressBookmark={pressBookmark}
      />
    );
  };

  //This renders the News items (mobile)
  const renderItemStoriesMobile = ({ item }) => {
    return (
      <MobileNewsItems
        updateSearch={updateSearch}
        goTo={goTo}
        item={item}
        selectedStoryTitle={selectedStoryTitle}
        selectedStoryAuthor={selectedStoryAuthor}
        bookmarked={bookmarked}
        pressBookmark={pressBookmark}
        saveRead={saveRead}
      />
    );
  };

  //This renders the list for category selection
  const renderItemCategories = ({ item }) => {
    return <CategoriesItem item={item} />;
  };

  //-------------------------------------------//
  //             Create Components             //
  //-------------------------------------------//

  //Bolds the first word (ArticleStart) of the article content
  const ArticleText = (props) => {
    const text = props.text.split(" ");
    return (
      <Text style={styles.articleText}>
        {text.map((text) => {
          if (text == props.ArticleStart) {
            return <Text style={styles.articleFirstWord}>{text}</Text>;
          }
          return `${text} `;
        })}
      </Text>
    );
  };

  //Create the top title that goes before the news stories in the articles list
  const topTitle = () => {
    return search.length > 0 ? (
      <View style={styles.topTitleContainer}>
        <TouchableOpacity
          onPress={() => (updateSearch(""), setShowBookmarks(false))}
        >
          <Feather
            name="x"
            size={25}
            color={"black"}
            style={styles.clearSearch}
          />
        </TouchableOpacity>
        <Animated.Text
          numberOfLines={1}
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Results: {search}
        </Animated.Text>
      </View>
    ) : showBookmarks && currentBookmarks?.length > 0 ? (
      <View style={styles.topTitleContainer}>
        <TouchableOpacity
          onPress={() => (updateSearch(""), setShowBookmarks(false))}
        >
          <Feather
            name="x"
            size={25}
            color={"black"}
            style={styles.clearSearch}
          />
        </TouchableOpacity>
        <Animated.Text
          numberOfLines={1}
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Bookmarked Stories
        </Animated.Text>
      </View>
    ) : Math.abs(currentCategoriesSelection?.length - CategoriesData.length) ==
      CategoriesData.length ? (
      <View style={styles.topTitleContainer}>
        <Animated.Text
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Top Stories
        </Animated.Text>
      </View>
    ) : Math.abs(currentCategoriesSelection?.length - CategoriesData.length) ==
      0 ? (
      <View style={styles.topTitleContainer}>
        <Animated.Text
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Top Stories
        </Animated.Text>
      </View>
    ) : isNaN(
        Math.abs(currentCategoriesSelection?.length - CategoriesData.length)
      ) ? (
      <View style={styles.topTitleContainer}>
        <Animated.Text
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Top Stories
        </Animated.Text>
      </View>
    ) : (
      <View style={styles.topTitleContainer}>
        <Animated.Text
          style={[
            { transform: [{ scale }], opacity: opacity },
            styles.topTitle,
          ]}
        >
          Top Stories
        </Animated.Text>
        <TouchableOpacity onPress={() => setPressedCategories(true)}>
          <Animated.Text
            style={[
              { transform: [{ scale }], opacity: opacity },
              styles.subTitle,
            ]}
          >
            (categories filtered)
          </Animated.Text>
        </TouchableOpacity>
      </View>
    );
  };

  if (deviceType == "browser") {
    return (
      <View style={styles.container}>
        <Overlay //Pressed Sources
          isVisible={pressedSources}
          fullScreen={true}
          overlayStyle={styles.overlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: 700,
              width: 850,
              backgroundColor: "white",
              borderRadius: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                width: "100%",
                height: 70,
                padding: 30,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                View a Source
              </Text>
              <TextInput
                maxLength={30}
                onChangeText={(newVal) => updateSourceSearch(newVal)}
                placeholder="Search Sources..."
                placeholderTextColor={"#4d4d4d"}
                value={sourceSearch}
                style={{
                  width: "50%",
                  alignSelf: "center",
                  backgroundColor: "white",
                  borderColor: colors.greyBorder,
                  borderWidth: 1,
                  borderRadius: 100,
                  height: 40,
                  paddingHorizontal: 15,
                  fontFamily: "Inter_700Bold",
                }}
              />
            </View>
            <View
              style={{
                width: "100%",
                height: 530,
                paddingTop: 20,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={sourceSearch == "" ? SearchSourceData : sourceSearchData}
                renderItem={renderItemSources}
                scrollEnabled={true}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
                keyExtractor={(item) => item.ArticleAuthor}
                showsVerticalScrollIndicator={false}
                numColumns={3}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                height: 100,
                alignItems: "center",
                backgroundColor: colors.greySidebar,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Pressable
                onPress={() => setPressedSources(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered
                      ? colors.greyCancel
                      : colors.greyCancelHover,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <Overlay //Pressed Categories
          isVisible={pressedCategories}
          fullScreen={true}
          overlayStyle={styles.overlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: 450,
              width: 650,
              backgroundColor: colors.greySidebar,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                height: 70,
                justifyContent: "center",
                width: "100%",
                padding: 30,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                Select Categories
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                height: 280,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={CategoriesData}
                renderItem={renderItemCategories}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                scrollEnabled={true}
                keyExtractor={(item) => item.index}
                showsVerticalScrollIndicator={false}
                numColumns={3}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
                backgroundColor: colors.greySidebar,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Pressable
                onPress={() => setPressedCategories(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Save
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <View //Header
          style={{
            height: 70,
            width: width,
            backgroundColor: "black",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 25,
          }}
        >
          <Image
            source={require("../assets/NewsVues Full Logo White.png")}
            style={{ width: 150, height: 30 }}
          />

          <TextInput
            maxLength={30}
            onSubmitEditing={() => saveSearches(search)}
            onChangeText={(newVal) => updateSearch(newVal)}
            placeholder="Search Stories..."
            placeholderTextColor={colors.greyLightFont}
            value={search}
            style={{
              width: "25%",
              alignSelf: "center",
              backgroundColor: colors.greyDark,
              color: "white",
              borderRadius: 100,
              height: 40,
              paddingHorizontal: 15,
              fontFamily: "Inter_700Bold",
            }}
          />
        </View>
        <View //content container
          style={{ flexDirection: "row", width: width, height: height - 70 }}
        >
          <ScrollView //sidebar
            showsVerticalScrollIndicator={false}
            style={{
              width: 275,
              backgroundColor: colors.greySidebar,
              borderRightWidth: 1,
              borderColor: colors.greyBorder,
              height: height - 70,
              paddingVertical: 20,
              zIndex: 2,
            }}
          >
            <View style={{ justifyContent: "center" }}>
              <View
                style={{ width: "80%", alignSelf: "center", marginBottom: 15 }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_700Bold",
                    fontSize: 17,
                  }}
                >
                  Your Feed
                </Text>
              </View>
              <Hoverable //Top Stories
                style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => (updateSearch(""), setShowBookmarks(false))}
                    style={{
                      paddingVertical: 5,
                      alignItems: "center",
                      borderRadius: 5,
                      paddingLeft: 15,
                      flexDirection: "row",
                      backgroundColor: hovered && "#e7f2fd",
                    }}
                  >
                    <Feather
                      name="list"
                      size={19}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                      style={{ paddingRight: 10 }}
                    />
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 14,
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Top Stories
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable //Bookmarks
                style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
                onMouseEnter={() => setBookmarkButtonHovering(true)}
                onMouseLeave={() => setBookmarkButtonHovering(false)}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={!currentBookmarks?.length > 0}
                    onPress={() => setShowBookmarks(true)}
                    style={{
                      paddingVertical: 5,
                      alignItems: "center",
                      borderRadius: 5,
                      paddingLeft: 15,
                      flexDirection: "row",
                      backgroundColor: hovered
                        ? currentBookmarks?.length > 0
                          ? "#e7f2fd"
                          : colors.greyCancel
                        : "transparent",
                    }}
                  >
                    <Feather
                      name="bookmark"
                      size={19}
                      color={
                        hovered
                          ? currentBookmarks?.length > 0
                            ? colors.blueLabelBorder
                            : colors.greyDark
                          : colors.greyDark
                      }
                      style={{ paddingRight: 10 }}
                    />
                    {!currentBookmarks?.length > 0 && bookmarkButtonHovering ? (
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 14,
                          color: hovered
                            ? currentBookmarks?.length > 0
                              ? colors.blueLabelBorder
                              : colors.greyDark
                            : colors.greyDark,
                        }}
                      >
                        No Bookmarks Found
                      </Text>
                    ) : (
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 14,
                          color: hovered
                            ? currentBookmarks?.length > 0
                              ? colors.blueLabelBorder
                              : colors.greyDark
                            : colors.greyDark,
                        }}
                      >
                        Bookmarks
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable //Categories
                style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    style={{
                      paddingVertical: 5,
                      alignItems: "center",
                      borderRadius: 5,
                      paddingLeft: 15,
                      flexDirection: "row",
                      backgroundColor: hovered && "#e7f2fd",
                    }}
                    onPress={() => setPressedCategories(true)}
                  >
                    <Feather
                      name="toggle-right"
                      size={19}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                      style={{ paddingRight: 10 }}
                    />
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 14,
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Categories
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable //Sources
                style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setPressedSources(true)}
                    style={{
                      paddingVertical: 5,
                      alignItems: "center",
                      borderRadius: 5,
                      paddingLeft: 15,
                      flexDirection: "row",
                      backgroundColor: hovered && "#e7f2fd",
                    }}
                  >
                    <Feather
                      name="type"
                      size={19}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                      style={{ paddingRight: 10 }}
                    />
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 14,
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Sources
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
              {currentSavedSearches?.length > 0 && (
                <View //Recent Searches
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    marginBottom: 15,
                    marginTop: 30,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_700Bold",
                      fontSize: 17,
                    }}
                  >
                    Searches
                  </Text>

                  <TouchableOpacity onPress={() => clearRecentSearch()}>
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 13,
                        color: "#e91d63",
                      }}
                    >
                      Clear
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {currentSavedSearches?.length > 0 && (
                <FlatList
                  data={currentSavedSearches}
                  renderItem={renderItemSearchHistory}
                  scrollEnabled={false}
                  keyExtractor={(item) => item.id}
                  showsVerticalScrollIndicator={false}
                  extraData={triggerSavedSearchRefresh}
                  style={{ flexGrow: 0 }}
                />
              )}
              {currentReadArticles?.length > 0 && (
                <View //Recently Read
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    marginBottom: 15,
                    marginTop: 30,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_700Bold",
                      fontSize: 17,
                    }}
                  >
                    Recently Read
                  </Text>

                  <TouchableOpacity onPress={() => clearRecentRead()}>
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 13,
                        color: "#e91d63",
                      }}
                    >
                      Clear
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {currentReadArticles?.length > 0 && (
                <FlatList
                  data={currentReadArticles}
                  renderItem={renderItemReadArticles}
                  scrollEnabled={false}
                  keyExtractor={(item) => item.id}
                  showsVerticalScrollIndicator={false}
                  extraData={triggerReadArticles}
                  style={{ flexGrow: 0 }}
                />
              )}
              <View
                style={{
                  width: "80%",
                  alignSelf: "center",
                  marginBottom: 15,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_700Bold",
                    fontSize: 17,
                    paddingBottom: 15,
                  }}
                >
                  Topics
                </Text>
                {CategoriesData.map((item) => {
                  return (
                    <Hoverable //Top Stories
                      style={{
                        width: "100%",
                        alignSelf: "center",
                        marginBottom: 5,
                      }}
                    >
                      {({ hovered }) => (
                        <TouchableOpacity
                          onPress={() => (
                            updateSearch(item.Category),
                            saveSearches(item.Category)
                          )}
                          style={{
                            paddingVertical: 5,
                            alignItems: "center",
                            borderRadius: 5,
                            paddingLeft: 15,
                            flexDirection: "row",
                            backgroundColor: hovered && "#e7f2fd",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Inter_600SemiBold",
                              fontSize: 14,
                              color: hovered
                                ? colors.blueLabelBorder
                                : colors.greyDark,
                            }}
                          >
                            {item.Category}
                          </Text>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  );
                })}
              </View>
            </View>
          </ScrollView>
          <View //stories
            style={{
              width: width * 0.55 - 275,
              backgroundColor: "white",
              height: "100%",
              borderRightWidth: 0,
              borderColor: colors.greyBorder,
            }}
          >
            <View style={{ padding: 10 }}>
              {!hidePromo && ( //NewsVues Promo
                <View
                  style={{
                    width: "100%",
                    alignSelf: "center",
                    borderRadius: 10,
                    padding: 20,
                    backgroundColor: "#f2f8ff",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View
                        style={{
                          width: 25,
                          height: 25,
                          backgroundColor: "white",
                          borderRadius: 100,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          source={require("../assets/icon.png")}
                          style={{ width: "80%", height: "80%" }}
                        />
                      </View>
                      <Text
                        style={{
                          color: colors.greyLightFont,
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 11,
                          paddingLeft: 10,
                        }}
                      >
                        NewsVues
                      </Text>
                    </View>

                    <Text
                      style={{
                        paddingTop: 10,
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                      }}
                    >
                      Want AI-powered news directly on your phone? A revamped
                      NewsVues app coming soon!
                    </Text>
                  </View>

                  <View
                    style={{
                      justifyContent: "center",
                      width: "30%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../assets/PromoImage.png")}
                      style={{ width: 90, height: 90, borderRadius: 10 }}
                    />
                  </View>
                  <TouchableOpacity
                    style={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      borderWidth: 0,
                      borderColor: colors.greyBorder,
                      borderRadius: 100,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      marginRight: -10,
                      marginTop: -10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => setHidePromo(true)}
                  >
                    <View>
                      <Feather name="x" size={15} color={colors.greyDark} />
                    </View>
                  </TouchableOpacity>
                </View>
              )}
              {!filteredNewsData.length > 0 && (
                <ActivityIndicator
                  size={"large"}
                  color={colors.greyDark}
                  style={{ paddingTop: 30 }}
                />
              )}
            </View>

            {filteredNewsData.length > 0 && (
              <FlatList
                ListHeaderComponent={topTitle}
                ref={flatListRef}
                data={
                  search == ""
                    ? showBookmarks && currentBookmarks?.length > 0
                      ? currentBookmarks
                      : filteredNewsData
                    : searchArticleData
                }
                onScroll={Animated.event(
                  [
                    {
                      nativeEvent: { contentOffset: { y: scrollY } },
                    },
                  ],
                  { useNativeDriver: false }
                )}
                renderItem={renderItemSavedStories}
                scrollEnabled={true}
                keyExtractor={(item) => item.ArticleTitle}
                showsVerticalScrollIndicator={true}
                initialNumToRender={15}
                onEndReachedThreshold={0.2}
                onEndReached={() => infiniteScroll()}
                style={{ paddingTop: 20 }}
                extraData={[
                  currentCategoriesSelection,
                  scrollRefresh,
                  showBookmarks,
                ]}
              />
            )}
          </View>

          <ScrollView //Article
            style={{
              width: width * 0.45,
              backgroundColor: "white",
              height: "100%",
            }}
          >
            <View
              style={{
                width: "95%",
                height: "100%",
                alignSelf: "center",
                padding: 30,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => updateSearch(selectedStoryAuthor)}
                >
                  <Image
                    source={selectedStoryAuthorIcon}
                    style={{ height: 30, width: 30, borderRadius: 100 }}
                  />
                  <Text
                    style={{ paddingLeft: 10, fontFamily: "Inter_600SemiBold" }}
                  >
                    {selectedStoryAuthor}
                  </Text>
                </TouchableOpacity>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {newsStories?.length > 0 && (
                    <TouchableOpacity
                      style={{
                        backgroundColor: colors.greyCancel,
                        width: 135,
                        alignItems: "center",
                        justifyContent: "center",
                        height: 35,
                        borderRadius: 100,
                      }}
                      onPress={() => updateSearch(selectedStoryArticleCategory)}
                    >
                      <Text
                        style={{
                          fontSize: 13,
                          color: colors.greyDark,
                          fontFamily: "Inter_600SemiBold",
                        }}
                      >
                        {selectedStoryArticleCategory}
                      </Text>
                    </TouchableOpacity>
                  )}
                  <View style={{ width: 10 }} />
                  <TouchableOpacity
                    onPress={() =>
                      pressBookmark(selectedStoryArticleLink, bookmarkArticle)
                    }
                  >
                    {bookmarked.includes(selectedStoryArticleLink)
                      ? newsStories?.length > 0 && (
                          <Image
                            source={require("../assets/clickedBookmark.png")}
                            style={{ height: 30, width: 25 }}
                          />
                        )
                      : newsStories?.length > 0 && (
                          <Image
                            source={require("../assets/clearBookmark.png")}
                            style={{ height: 30, width: 25 }}
                          />
                        )}
                  </TouchableOpacity>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  fontSize: 27,
                  paddingTop: 25,
                  paddingBottom: 15,
                }}
              >
                {selectedStoryTitle}
              </Text>
              <Image
                source={selectedStoryImage}
                style={{
                  width: "100%",
                  height: "75%",
                  borderRadius: 10,
                  marginTop: 20,
                }}
              />
              <ArticleText
                ArticleStart={selectedStoryArticleStart}
                text={
                  selectedStoryArticleStart + "  " + selectedStoryArticleContent
                }
              />
              {newsStories?.length > 0 && (
                <View
                  style={{
                    backgroundColor: colors.greyCancel,
                    height: 40,
                    width: 200,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: 30,
                  }}
                >
                  <TouchableOpacity
                    onPress={() =>
                      saveRead(selectedStoryTitle, selectedStoryArticleLink)
                    }
                    style={{
                      height: 40,
                      width: 200,
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.greyDark,
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                      }}
                    >
                      📰 Read the Full Article
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              <View style={{ height: "10%" }} />
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
  if (deviceType == "tablet") {
    return (
      <ScrollView style={styles.container}>
        <Overlay //Pressed Sources
          isVisible={pressedSources}
          fullScreen={true}
          overlayStyle={styles.overlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: 700,
              width: 700,
              backgroundColor: "white",
              borderRadius: 10,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                width: "100%",
                height: 70,
                padding: 30,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                View a Source
              </Text>
              <TextInput
                maxLength={30}
                onChangeText={(newVal) => updateSourceSearch(newVal)}
                placeholder="Search Sources..."
                placeholderTextColor={"#4d4d4d"}
                value={sourceSearch}
                style={{
                  width: "50%",
                  alignSelf: "center",
                  backgroundColor: "white",
                  borderColor: colors.greyBorder,
                  borderWidth: 1,
                  borderRadius: 100,
                  height: 40,
                  paddingHorizontal: 15,
                  fontFamily: "Inter_700Bold",
                }}
              />
            </View>
            <View
              style={{
                width: "100%",
                height: 530,
                paddingTop: 20,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={sourceSearch == "" ? SearchSourceData : sourceSearchData}
                renderItem={renderItemSources}
                scrollEnabled={true}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
                keyExtractor={(item) => item.ArticleAuthor}
                showsVerticalScrollIndicator={false}
                numColumns={3}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                height: 100,
                alignItems: "center",
                backgroundColor: colors.greySidebar,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Pressable
                onPress={() => setPressedSources(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered
                      ? colors.greyCancel
                      : colors.greyCancelHover,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <Overlay //Pressed Categories
          isVisible={pressedCategories}
          fullScreen={true}
          overlayStyle={styles.overlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: 450,
              width: 650,
              backgroundColor: colors.greySidebar,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                height: 70,
                justifyContent: "center",
                width: "100%",
                padding: 30,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                Select Categories
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                height: 280,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={CategoriesData}
                renderItem={renderItemCategories}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                scrollEnabled={true}
                keyExtractor={(item) => item.index}
                showsVerticalScrollIndicator={false}
                numColumns={3}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
                backgroundColor: colors.greySidebar,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Pressable
                onPress={() => setPressedCategories(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Save
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <View //Header
          style={{
            height: 70,
            width: width,
            backgroundColor: "black",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 25,
          }}
        >
          <Image
            source={require("../assets/NewsVues Full Logo White.png")}
            style={{ width: 150, height: 30 }}
          />
          <View
            style={{
              flexDirection: "row",
              width: "50%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextInput
              maxLength={30}
              onSubmitEditing={() => saveSearches(search)}
              onChangeText={(newVal) => updateSearch(newVal)}
              placeholder="Search Stories..."
              placeholderTextColor={colors.greyLightFont}
              value={search}
              style={{
                width: "90%",
                alignSelf: "center",
                backgroundColor: colors.greyDark,
                color: "white",
                borderRadius: 100,
                height: 40,
                paddingHorizontal: 15,
                fontFamily: "Inter_700Bold",
              }}
            />
            <TouchableOpacity
              onPress={() => setTabletShowSidebar(!tabletShowSidebar)}
            >
              <Feather name="menu" size={25} color={"white"} />
            </TouchableOpacity>
          </View>
        </View>
        <View //content container
          style={{ flexDirection: "row", width: width, height: height - 70 }}
        >
          {tabletShowSidebar && (
            <Animatable.View //sidebar
              animation={"fadeInRightBig"}
              duration={300}
              style={{
                width: 275,
                backgroundColor: colors.greySidebar,
                borderLeftWidth: 1,
                position: "absolute",
                zIndex: 2,
                right: 0,
                borderColor: colors.greyBorder,
                height: "100%",
                zIndex: 2,
                shadowColor: "rgba(0, 0, 0, 0.4)",
                shadowRadius: 15,
                shadowOpacity: 0.7,
                shadowOffset: {
                  height: 1,
                  width: 4,
                },
              }}
            >
              <View
                style={{
                  height: height - 70 - 140,
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "80%",
                    alignSelf: "center",
                    marginBottom: 15,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_700Bold",
                      fontSize: 17,
                    }}
                  >
                    Your Feed
                  </Text>
                </View>
                <Hoverable //Top Stories
                  style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => (
                        updateSearch(""), setShowBookmarks(false)
                      )}
                      style={{
                        paddingVertical: 5,
                        alignItems: "center",
                        borderRadius: 5,
                        paddingLeft: 15,
                        flexDirection: "row",
                        backgroundColor: hovered && "#e7f2fd",
                      }}
                    >
                      <Feather
                        name="list"
                        size={21}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                        style={{ paddingRight: 10 }}
                      />
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 15,
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Top Stories
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <Hoverable //Bookmarks
                  style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
                  onMouseEnter={() => setBookmarkButtonHovering(true)}
                  onMouseLeave={() => setBookmarkButtonHovering(false)}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      disabled={!currentBookmarks?.length > 0}
                      onPress={() => setShowBookmarks(true)}
                      style={{
                        paddingVertical: 5,
                        alignItems: "center",
                        borderRadius: 5,
                        paddingLeft: 15,
                        flexDirection: "row",
                        backgroundColor: hovered
                          ? currentBookmarks?.length > 0
                            ? "#e7f2fd"
                            : colors.greyCancel
                          : "transparent",
                      }}
                    >
                      <Feather
                        name="bookmark"
                        size={21}
                        color={
                          hovered
                            ? currentBookmarks?.length > 0
                              ? colors.blueLabelBorder
                              : colors.greyDark
                            : colors.greyDark
                        }
                        style={{ paddingRight: 10 }}
                      />
                      {!currentBookmarks?.length > 0 &&
                      bookmarkButtonHovering ? (
                        <Text
                          style={{
                            fontFamily: "Inter_600SemiBold",
                            fontSize: 15,
                            color: hovered
                              ? currentBookmarks?.length > 0
                                ? colors.blueLabelBorder
                                : colors.greyDark
                              : colors.greyDark,
                          }}
                        >
                          No Bookmarks Found
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontFamily: "Inter_600SemiBold",
                            fontSize: 15,
                            color: hovered
                              ? currentBookmarks?.length > 0
                                ? colors.blueLabelBorder
                                : colors.greyDark
                              : colors.greyDark,
                          }}
                        >
                          Bookmarks
                        </Text>
                      )}
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <Hoverable //Categories
                  style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      style={{
                        paddingVertical: 5,
                        alignItems: "center",
                        borderRadius: 5,
                        paddingLeft: 15,
                        flexDirection: "row",
                        backgroundColor: hovered && "#e7f2fd",
                      }}
                      onPress={() => setPressedCategories(true)}
                    >
                      <Feather
                        name="toggle-right"
                        size={21}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                        style={{ paddingRight: 10 }}
                      />
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 15,
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Categories
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                <Hoverable //Sources
                  style={{ width: "80%", alignSelf: "center", marginBottom: 5 }}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => setPressedSources(true)}
                      style={{
                        paddingVertical: 5,
                        alignItems: "center",
                        borderRadius: 5,
                        paddingLeft: 15,
                        flexDirection: "row",
                        backgroundColor: hovered && "#e7f2fd",
                      }}
                    >
                      <Feather
                        name="type"
                        size={21}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                        style={{ paddingRight: 10 }}
                      />
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 15,
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Sources
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                {currentSavedSearches?.length > 0 && (
                  <View //Recent Searches
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 15,
                      marginTop: 30,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 17,
                      }}
                    >
                      Searches
                    </Text>

                    <TouchableOpacity onPress={() => clearRecentSearch()}>
                      <Text
                        style={{
                          fontFamily: "Inter_500Medium",
                          fontSize: 13,
                          color: "#e91d63",
                        }}
                      >
                        Clear
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                {currentSavedSearches?.length > 0 && (
                  <FlatList
                    data={currentSavedSearches}
                    renderItem={renderItemSearchHistory}
                    scrollEnabled={false}
                    keyExtractor={(item) => item.id}
                    showsVerticalScrollIndicator={false}
                    extraData={triggerSavedSearchRefresh}
                    style={{ flexGrow: 0 }}
                  />
                )}
                {currentReadArticles?.length > 0 && (
                  <View //Recently Read
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 15,
                      marginTop: 30,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 17,
                      }}
                    >
                      Recently Read
                    </Text>

                    <TouchableOpacity onPress={() => clearRecentRead()}>
                      <Text
                        style={{
                          fontFamily: "Inter_500Medium",
                          fontSize: 13,
                          color: "#e91d63",
                        }}
                      >
                        Clear
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                {currentReadArticles?.length > 0 && (
                  <FlatList
                    data={currentReadArticles}
                    renderItem={renderItemReadArticles}
                    scrollEnabled={false}
                    keyExtractor={(item) => item.id}
                    showsVerticalScrollIndicator={false}
                    extraData={triggerReadArticles}
                    style={{ flexGrow: 0 }}
                  />
                )}
              </View>
            </Animatable.View>
          )}
          {tabletShowSidebar && (
            <View style={{ position: "absolute", zIndex: 1 }}>
              <TouchableOpacity
                onPress={() => setTabletShowSidebar(false)}
                style={{
                  height: height - 70,
                  width: width,
                  zIndex: 1,
                  backgroundColor: "transparent",
                }}
              />
            </View>
          )}
          <View //stories
            style={{
              width: width * 0.55,
              backgroundColor: "white",
              height: "100%",
              borderRightWidth: 0,
              borderColor: colors.greyBorder,
            }}
          >
            {!hidePromo && (
              <Animated.View style={{ padding: 20 }}>
                <View
                  style={{
                    width: "100%",
                    alignSelf: "center",
                    borderRadius: 10,
                    padding: 20,
                    backgroundColor: "#f2f8ff",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View
                        style={{
                          width: 25,
                          height: 25,
                          backgroundColor: "white",
                          borderRadius: 100,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          source={require("../assets/icon.png")}
                          style={{ width: "80%", height: "80%" }}
                        />
                      </View>
                      <Text
                        style={{
                          color: colors.greyLightFont,
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 11,
                          paddingLeft: 10,
                        }}
                      >
                        NewsVues
                      </Text>
                    </View>

                    <Text
                      style={{
                        paddingTop: 10,
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                      }}
                    >
                      Want AI-powered news directly on your phone? A revamped
                      NewsVues app coming soon!
                    </Text>
                  </View>

                  <View
                    style={{
                      justifyContent: "center",
                      width: "30%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../assets/PromoImage.png")}
                      style={{ width: 90, height: 90, borderRadius: 10 }}
                    />
                  </View>
                  <TouchableOpacity
                    style={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      borderWidth: 0,
                      borderColor: colors.greyBorder,
                      borderRadius: 100,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      marginRight: -10,
                      marginTop: -10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => setHidePromo(true)}
                  >
                    <View>
                      <Feather name="x" size={15} color={colors.greyDark} />
                    </View>
                  </TouchableOpacity>
                </View>
              </Animated.View>
            )}
            {filteredNewsData.length > 0 && (
              <FlatList
                ListHeaderComponent={topTitle}
                ref={flatListRef}
                data={
                  search == ""
                    ? showBookmarks && currentBookmarks?.length > 0
                      ? currentBookmarks
                      : filteredNewsData
                    : searchArticleData
                }
                onScroll={Animated.event(
                  [
                    {
                      nativeEvent: { contentOffset: { y: scrollY } },
                    },
                  ],
                  { useNativeDriver: false }
                )}
                renderItem={renderItemSavedStories}
                scrollEnabled={true}
                keyExtractor={(item) => item.ArticleTitle}
                showsVerticalScrollIndicator={true}
                initialNumToRender={15}
                onEndReachedThreshold={0.2}
                onEndReached={() => infiniteScroll()}
                style={{ paddingTop: 25, paddingHorizontal: 10 }}
                extraData={[
                  currentCategoriesSelection,
                  scrollRefresh,
                  showBookmarks,
                ]}
              />
            )}
          </View>

          <View //Article
            style={{
              width: width * 0.45,
              backgroundColor: "white",
              height: "100%",
            }}
          >
            <View
              style={{
                width: "100%",
                height: "100%",
                alignSelf: "center",
                padding: 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => updateSearch(selectedStoryAuthor)}
                >
                  <Image
                    source={selectedStoryAuthorIcon}
                    style={{ height: 30, width: 30, borderRadius: 100 }}
                  />
                  <Text
                    style={{ paddingLeft: 10, fontFamily: "Inter_600SemiBold" }}
                  >
                    {selectedStoryAuthor}
                  </Text>
                </TouchableOpacity>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {newsStories?.length > 0 && (
                    <TouchableOpacity
                      style={{
                        backgroundColor: colors.greyCancel,
                        width: 135,
                        alignItems: "center",
                        justifyContent: "center",
                        height: 35,
                        borderRadius: 100,
                      }}
                      onPress={() => updateSearch(selectedStoryArticleCategory)}
                    >
                      <Text
                        style={{
                          fontSize: 13,
                          color: colors.greyDark,
                          fontFamily: "Inter_500Medium",
                        }}
                      >
                        {selectedStoryArticleCategory}
                      </Text>
                    </TouchableOpacity>
                  )}
                  <View style={{ width: 10 }} />
                  <TouchableOpacity
                    onPress={() =>
                      pressBookmark(selectedStoryArticleLink, bookmarkArticle)
                    }
                  >
                    {bookmarked.includes(selectedStoryArticleLink)
                      ? newsStories?.length > 0 && (
                          <Image
                            source={require("../assets/clickedBookmark.png")}
                            style={{ height: 25, width: 21 }}
                          />
                        )
                      : newsStories?.length > 0 && (
                          <Image
                            source={require("../assets/clearBookmark.png")}
                            style={{ height: 25, width: 21 }}
                          />
                        )}
                  </TouchableOpacity>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  fontSize: 25,
                  paddingTop: 25,
                  paddingBottom: 15,
                }}
              >
                {selectedStoryTitle}
              </Text>
              <Image
                source={selectedStoryImage}
                style={{
                  width: "100%",
                  height: "30%",
                  borderRadius: 10,
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />
              <ArticleText
                ArticleStart={selectedStoryArticleStart}
                text={
                  selectedStoryArticleStart + "  " + selectedStoryArticleContent
                }
              />
              {newsStories?.length > 0 && (
                <View
                  style={{
                    backgroundColor: colors.greyCancel,
                    height: 40,
                    width: 200,
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    marginTop: 30,
                  }}
                >
                  <TouchableOpacity
                    onPress={() =>
                      saveRead(selectedStoryTitle, selectedStoryArticleLink)
                    }
                    style={{
                      height: 40,
                      width: 200,
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.greyDark,
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                      }}
                    >
                      📰 Read the Full Article
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              <View style={{ height: "10%" }} />
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
  if (deviceType == "mobile") {
    return (
      <View style={styles.container}>
        <Overlay //Pressed Sources
          isVisible={pressedSources}
          fullScreen={true}
          overlayStyle={styles.mobileOverlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: height,
              width: width,
              backgroundColor: "white",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                backgroundColor: "white",
                width: "100%",
                height: 70,
                padding: 30,
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                View a Source
              </Text>
            </View>
            <View
              style={{
                backgroundColor: colors.greySidebar,
                height: 60,
                justifyContent: "center",
              }}
            >
              <TextInput
                maxLength={30}
                onChangeText={(newVal) => updateSourceSearch(newVal)}
                placeholder="Search Sources..."
                placeholderTextColor={"#4d4d4d"}
                value={sourceSearch}
                style={{
                  width: "80%",
                  alignSelf: "center",
                  backgroundColor: "white",
                  borderColor: colors.greyBorder,
                  borderWidth: 1,
                  borderRadius: 100,
                  height: 40,
                  paddingHorizontal: 15,
                  fontFamily: "Inter_700Bold",
                }}
              />
            </View>
            <View
              style={{
                width: "100%",
                height: height - 230,
                paddingTop: 20,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={sourceSearch == "" ? SearchSourceData : sourceSearchData}
                renderItem={renderItemSources}
                scrollEnabled={true}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                }}
                keyExtractor={(item) => item.ArticleAuthor}
                showsVerticalScrollIndicator={false}
                numColumns={2}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                height: 100,
                alignItems: "center",
                backgroundColor: colors.greySidebar,
              }}
            >
              <Pressable
                onPress={() => setPressedSources(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered
                      ? colors.greyCancel
                      : colors.greyCancelHover,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <Overlay //Pressed Categories
          isVisible={pressedCategories}
          fullScreen={true}
          overlayStyle={styles.overlayStyle}
        >
          <Animatable.View
            animation={"zoomIn"}
            duration={300}
            style={{
              height: height,
              width: width,
              backgroundColor: colors.greySidebar,
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                height: 70,
                justifyContent: "center",
                width: "100%",
                padding: 30,

                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_800ExtraBold",
                  fontSize: 23,
                }}
              >
                Select Categories
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                height: height - 170,
                backgroundColor: colors.greySidebar,
              }}
            >
              <FlatList
                data={CategoriesData}
                renderItem={renderItemCategories}
                contentContainerStyle={{
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                scrollEnabled={true}
                keyExtractor={(item) => item.index}
                showsVerticalScrollIndicator={false}
                numColumns={2}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: 100,
                backgroundColor: colors.greySidebar,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <Pressable
                onPress={() => setPressedCategories(false)}
                style={({ hovered }) => [
                  {
                    borderRadius: 100,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 150,
                    height: 40,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Save
                </Text>
              </Pressable>
            </View>
          </Animatable.View>
        </Overlay>

        <View //Header
          style={{
            height: 70,
            width: width,
            backgroundColor: "black",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingHorizontal: 25,
            zIndex: 3,
          }}
        >
          <Image
            source={require("../assets/NewsVues Full Logo White.png")}
            style={{ width: 150, height: 30 }}
          />
          <View
            style={{
              flexDirection: "row",
              width: "20%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity
              onPress={() => setMobileShowSearch(!mobileShowSearch)}
            >
              <Feather name="search" size={21} color={"white"} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setMobileShowSidebar(!mobileShowSidebar)}
            >
              <Feather name="menu" size={21} color={"white"} />
            </TouchableOpacity>
          </View>
        </View>
        {mobileShowSearch && (
          <Animatable.View //Search
            animation={"fadeInDown"}
            duration={300}
            style={{
              height: 70,
              position: "absolute",
              top: 70,
              width: width,
              backgroundColor: "white",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 25,
              zIndex: 2,
              shadowColor: "rgba(0, 0, 0, 0.2)",
              shadowRadius: 15,
              shadowOffset: {
                height: 2,
                width: 1,
              },
            }}
          >
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInput
                maxLength={30}
                onSubmitEditing={() => (
                  saveSearches(search), setMobileShowSearch(false)
                )}
                onChangeText={(newVal) => updateSearch(newVal)}
                placeholder="Search Stories..."
                placeholderTextColor={"#4d4d4d"}
                value={search}
                style={{
                  width: "85%",
                  alignSelf: "center",
                  backgroundColor: "#f1f1f1",
                  color: "black",
                  borderRadius: 100,
                  height: 40,
                  paddingHorizontal: 15,
                  fontFamily: "Inter_700Bold",
                }}
              />
            </View>
          </Animatable.View>
        )}
        <View //content container
          style={{ flexDirection: "row", width: width, height: height - 70 }}
        >
          {mobileShowSidebar && (
            <Animatable.View //sidebar
              animation={"fadeInRightBig"}
              duration={300}
              style={{
                width: 275,
                backgroundColor: "white",
                borderLeftWidth: 1,
                position: "absolute",
                zIndex: 2,
                right: 0,
                borderColor: colors.greyBorder,
                height: height - 70,
                zIndex: 2,
                shadowColor: "rgba(0, 0, 0, 0.6)",
                shadowRadius: 40,
                shadowOpacity: 0.7,
                shadowOffset: {
                  height: 1,
                  width: 4,
                },
              }}
            >
              <View style={{ height: height - 140 }}>
                <ScrollView
                  style={{
                    height: "100%",
                    paddingVertical: 40,
                  }}
                >
                  <View
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 17,
                      }}
                    >
                      Your Feed
                    </Text>
                  </View>
                  <Hoverable //Top Stories
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 5,
                    }}
                  >
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => (
                          updateSearch(""),
                          setShowBookmarks(false),
                          setMobileShowSidebar(false)
                        )}
                        style={{
                          paddingVertical: 5,
                          alignItems: "center",
                          borderRadius: 5,
                          paddingLeft: 15,
                          flexDirection: "row",
                          backgroundColor: hovered && "#e7f2fd",
                        }}
                      >
                        <Feather
                          name="list"
                          size={19}
                          color={
                            hovered ? colors.blueLabelBorder : colors.greyDark
                          }
                          style={{ paddingRight: 10 }}
                        />
                        <Text
                          style={{
                            fontFamily: "Inter_600SemiBold",
                            fontSize: 14,
                            color: hovered
                              ? colors.blueLabelBorder
                              : colors.greyDark,
                          }}
                        >
                          Top Stories
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                  <Hoverable //Bookmarks
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 5,
                    }}
                    onMouseEnter={() => setBookmarkButtonHovering(true)}
                    onMouseLeave={() => setBookmarkButtonHovering(false)}
                  >
                    {({ hovered }) => (
                      <TouchableOpacity
                        disabled={!currentBookmarks?.length > 0}
                        onPress={() => (
                          setShowBookmarks(true), setMobileShowSidebar(false)
                        )}
                        style={{
                          paddingVertical: 5,
                          alignItems: "center",
                          borderRadius: 5,
                          paddingLeft: 15,
                          flexDirection: "row",
                          backgroundColor: hovered
                            ? currentBookmarks?.length > 0
                              ? "#e7f2fd"
                              : colors.greyCancel
                            : "transparent",
                        }}
                      >
                        <Feather
                          name="bookmark"
                          size={19}
                          color={
                            hovered
                              ? currentBookmarks?.length > 0
                                ? colors.blueLabelBorder
                                : colors.greyDark
                              : colors.greyDark
                          }
                          style={{ paddingRight: 10 }}
                        />
                        {!currentBookmarks?.length > 0 &&
                        bookmarkButtonHovering ? (
                          <Text
                            style={{
                              fontFamily: "Inter_600SemiBold",
                              fontSize: 14,
                              color: hovered
                                ? currentBookmarks?.length > 0
                                  ? colors.blueLabelBorder
                                  : colors.greyDark
                                : colors.greyDark,
                            }}
                          >
                            No Bookmarks Found
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontFamily: "Inter_600SemiBold",
                              fontSize: 14,
                              color: hovered
                                ? currentBookmarks?.length > 0
                                  ? colors.blueLabelBorder
                                  : colors.greyDark
                                : colors.greyDark,
                            }}
                          >
                            Bookmarks
                          </Text>
                        )}
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                  <Hoverable //Categories
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 5,
                    }}
                  >
                    {({ hovered }) => (
                      <TouchableOpacity
                        style={{
                          paddingVertical: 5,
                          alignItems: "center",
                          borderRadius: 5,
                          paddingLeft: 15,
                          flexDirection: "row",
                          backgroundColor: hovered && "#e7f2fd",
                        }}
                        onPress={() => setPressedCategories(true)}
                      >
                        <Feather
                          name="toggle-right"
                          size={19}
                          color={
                            hovered ? colors.blueLabelBorder : colors.greyDark
                          }
                          style={{ paddingRight: 10 }}
                        />
                        <Text
                          style={{
                            fontFamily: "Inter_600SemiBold",
                            fontSize: 14,
                            color: hovered
                              ? colors.blueLabelBorder
                              : colors.greyDark,
                          }}
                        >
                          Categories
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                  <Hoverable //Sources
                    style={{
                      width: "80%",
                      alignSelf: "center",
                      marginBottom: 5,
                    }}
                  >
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => setPressedSources(true)}
                        style={{
                          paddingVertical: 5,
                          alignItems: "center",
                          borderRadius: 5,
                          paddingLeft: 15,
                          flexDirection: "row",
                          backgroundColor: hovered && "#e7f2fd",
                        }}
                      >
                        <Feather
                          name="type"
                          size={19}
                          color={
                            hovered ? colors.blueLabelBorder : colors.greyDark
                          }
                          style={{ paddingRight: 10 }}
                        />
                        <Text
                          style={{
                            fontFamily: "Inter_600SemiBold",
                            fontSize: 14,
                            color: hovered
                              ? colors.blueLabelBorder
                              : colors.greyDark,
                          }}
                        >
                          Sources
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                  {currentSavedSearches?.length > 0 && (
                    <View //Recent Searches
                      style={{
                        width: "80%",
                        alignSelf: "center",
                        marginBottom: 15,
                        marginTop: 30,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Inter_700Bold",
                          fontSize: 17,
                        }}
                      >
                        Searches
                      </Text>

                      <TouchableOpacity onPress={() => clearRecentSearch()}>
                        <Text
                          style={{
                            fontFamily: "Inter_500Medium",
                            fontSize: 13,
                            color: "#e91d63",
                          }}
                        >
                          Clear
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {currentSavedSearches?.length > 0 && (
                    <FlatList
                      data={currentSavedSearches}
                      renderItem={renderItemSearchHistory}
                      scrollEnabled={false}
                      keyExtractor={(item) => item.id}
                      showsVerticalScrollIndicator={false}
                      extraData={triggerSavedSearchRefresh}
                      style={{ flexGrow: 0 }}
                    />
                  )}
                  {currentReadArticles?.length > 0 && (
                    <View //Recently Read
                      style={{
                        width: "80%",
                        alignSelf: "center",
                        marginBottom: 15,
                        marginTop: 30,
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Inter_700Bold",
                          fontSize: 17,
                        }}
                      >
                        Recently Read
                      </Text>

                      <TouchableOpacity onPress={() => clearRecentRead()}>
                        <Text
                          style={{
                            fontFamily: "Inter_500Medium",
                            fontSize: 13,
                            color: "#e91d63",
                          }}
                        >
                          Clear
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {currentReadArticles?.length > 0 && (
                    <FlatList
                      data={currentReadArticles}
                      renderItem={renderItemReadArticles}
                      scrollEnabled={false}
                      keyExtractor={(item) => item.id}
                      showsVerticalScrollIndicator={false}
                      extraData={triggerReadArticles}
                      style={{ flexGrow: 0 }}
                    />
                  )}
                </ScrollView>
              </View>
            </Animatable.View>
          )}
          {mobileShowSidebar && (
            <View style={{ position: "absolute", zIndex: 1 }}>
              <TouchableOpacity
                onPress={() => setMobileShowSidebar(false)}
                style={{
                  height: height - 70,
                  width: width,
                  zIndex: 1,
                  backgroundColor: "transparent",
                }}
              />
            </View>
          )}
          <View //stories
            style={{
              width: width,
              backgroundColor: "white",
              height: "100%",
              borderRightWidth: 0,
              borderColor: colors.greyBorder,
            }}
          >
            {!hidePromo && (
              <Animated.View style={{ padding: 10 }}>
                <View
                  style={{
                    width: "100%",
                    alignSelf: "center",
                    borderRadius: 10,
                    padding: 20,
                    backgroundColor: "#f2f8ff",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "70%",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View
                        style={{
                          width: 25,
                          height: 25,
                          backgroundColor: "white",
                          borderRadius: 100,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          source={require("../assets/icon.png")}
                          style={{ width: "80%", height: "80%" }}
                        />
                      </View>
                      <Text
                        style={{
                          color: colors.greyLightFont,
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 11,
                          paddingLeft: 10,
                        }}
                      >
                        NewsVues
                      </Text>
                    </View>

                    <Text
                      style={{
                        paddingTop: 10,
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                      }}
                    >
                      Want AI-powered news directly on your phone? A revamped
                      NewsVues app coming soon!
                    </Text>
                  </View>

                  <View
                    style={{
                      justifyContent: "center",
                      width: "30%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      source={require("../assets/PromoImage.png")}
                      style={{ width: 90, height: 90, borderRadius: 10 }}
                    />
                  </View>
                  <TouchableOpacity
                    style={{
                      backgroundColor: "white",
                      width: 20,
                      height: 20,
                      borderWidth: 0,
                      borderColor: colors.greyBorder,
                      borderRadius: 100,
                      position: "absolute",
                      right: 0,
                      top: 0,
                      marginRight: -10,
                      marginTop: -10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => setHidePromo(true)}
                  >
                    <View>
                      <Feather name="x" size={15} color={colors.greyDark} />
                    </View>
                  </TouchableOpacity>
                </View>
              </Animated.View>
            )}
            {filteredNewsData.length > 0 && (
              <FlatList
                ListHeaderComponent={topTitle}
                ref={flatListRef}
                data={
                  search == ""
                    ? showBookmarks && currentBookmarks?.length > 0
                      ? currentBookmarks
                      : filteredNewsData
                    : searchArticleData
                }
                onScroll={Animated.event(
                  [
                    {
                      nativeEvent: { contentOffset: { y: scrollY } },
                    },
                  ],
                  { useNativeDriver: false }
                )}
                renderItem={renderItemStoriesMobile}
                scrollEnabled={true}
                keyExtractor={(item) => item.ArticleTitle}
                showsVerticalScrollIndicator={true}
                initialNumToRender={15}
                onEndReachedThreshold={0.2}
                onEndReached={() => infiniteScroll()}
                style={{ paddingTop: 20 }}
                extraData={[
                  currentCategoriesSelection,
                  scrollRefresh,
                  showBookmarks,
                ]}
              />
            )}
          </View>
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  topTitleContainer: { flexDirection: "row", alignItems: "center" },
  topTitle: {
    paddingLeft: 15,
    paddingVertical: 15,
    paddingRight: 5,
    fontFamily: "Inter_800ExtraBold",
    fontSize: 23,
  },
  articleText: {
    fontSize: 17,
    fontFamily: "Inter_400Regular",
    paddingTop: "7%",
    width: "100%",
    lineHeight: 21,
    color: "black",
  },
  articleFirstWord: { fontFamily: "Inter_700Bold", fontSize: 17 },
  clearSearch: { marginLeft: 10, marginRight: -5 },
  subTitle: {
    paddingVertical: 15,
    fontFamily: "Inter_500Medium",
    fontSize: 15,
    color: colors.greyDark,
  },
  container: { height: "100%", width: "100%" },
  header: {
    paddingLeft: 15,
    fontFamily: "Inter_800ExtraBold",
    fontSize: 35,
  },
  overlayStyle: {
    backgroundColor: "rgba(0,0,0,.5)",
    zIndex: 100,
    padding: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  mobileOverlayStyle: {
    backgroundColor: "rgba(0,0,0,.5)",
    zIndex: 100,
    justifyContent: "center",
    alignItems: "center",
  },
});
