import {
  SET_BOOKMARK,
  SET_CATEGORY,
  SET_SEARCH_HISTORY,
  SET_ARTICLES,
  SET_READ,
} from "./constants";
import { combineReducers } from "redux";

//Bookmarked Articles
const INITIAL_STATE_BOOKMARK = {
  bookmarkArticle: [],
};
function reducer_bookmark(state = INITIAL_STATE_BOOKMARK, action) {
  switch (action.type) {
    case SET_BOOKMARK:
      return {
        bookmarkArticle: action.bookmarkArticle,
      };
    default:
      return state;
  }
}

//Selected Categories
const INITIAL_STATE_CATEGORY = [
  "🇺🇸 US News",
  "🌏 Global News",
  "💼 Business",
  "🏛 Politics",
  "🤖 Technology",
  "🎥 Entertainment",
  "🧪 Science",
  "🏅 Sports",
  "🏥 Health",
];

function reducer_category(state = INITIAL_STATE_CATEGORY, action) {
  switch (action.type) {
    case SET_CATEGORY:
      return {
        categorySelections: action.categorySelections,
      };
    default:
      return state;
  }
}

//Search History
const INITIAL_STATE_SAVED_SEARCHES = [];
function reducer_saved_searches(state = INITIAL_STATE_SAVED_SEARCHES, action) {
  switch (action.type) {
    case SET_SEARCH_HISTORY:
      return {
        searchHistory: action.searchHistory,
      };
    default:
      return state;
  }
}

//News Articles
const INITIAL_STATE_ARTICLES = [];
function reducer_articles(state = INITIAL_STATE_ARTICLES, action) {
  switch (action.type) {
    case SET_ARTICLES:
      return {
        articles: action.articles,
      };
    default:
      return state;
  }
}
//Read Articles
const INITIAL_STATE_READ = [];
function reducer_read(state = INITIAL_STATE_READ, action) {
  switch (action.type) {
    case SET_READ:
      return {
        read: action.read,
      };
    default:
      return state;
  }
}

const reducer = combineReducers({
  bookmark: reducer_bookmark,
  category: reducer_category,
  searches: reducer_saved_searches,
  articles: reducer_articles,
  read: reducer_read,
});
export default reducer;
