import React from "react";
import { colors } from "../Styles/colors.js";
import { Text, View, Image } from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import { Ionicons } from "@expo/vector-icons";

export default function Error({ navigation }) {
  return (
    <View style={{ width: width, height: "100%" }}>
      <View style={{ width: "100%", height: "90%" }}>
        <Image
          source={require("../assets/404.png")}
          style={{ position: "absolute", width: width, height: "100%" }}
        />
        <View
          style={{
            position: "absolute",
            width: "35%",
            height: "20%",
            top: "50%",
            left: "15%",
          }}
        >
          <Text
            style={{
              paddingTop: 10,
              fontFamily: "Inter_700Bold",
              fontSize: 30,
              zIndex: 2,
              color: "black",
            }}
          >
            Uh-oh! PAGE NOT FOUND
          </Text>
          <Text
            style={{
              paddingTop: 20,
              fontFamily: "Inter_500Medium",
              fontSize: 20,
              zIndex: 2,
              color: "black",
              opacity: 0.6,
            }}
          >
            We can't find the page you're looking for. Use the button below to
            head back to the home page!
          </Text>
          <Pressable
            onPress={() => navigation.navigate("Home")}
            style={({ hovered }) => [
              {
                width: 200,
                height: 50,
                borderRadius: 10,
                alignItems: "center",
                marginTop: 20,
                flexDirection: "row",
              },
            ]}
          >
            <Ionicons name="arrow-back-outline" size={25} color={"black"} />
            <Text
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Back
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}
